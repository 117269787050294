<template>
  <div v-if="p_shipper.setting.length > 0">
    <div
      class="border p-3 rounded rounded-5 mb-3"
      v-for="data in p_shipper.setting"
      :key="data.id"
    >
      <p class="fw-bolder mb-3">
        <span v-if="data.value === 'pickList'">撿貨單</span>
        <span v-else-if="data.value === 'shipper'">出貨明細單</span>
        <span v-else-if="data.value === 'picture'">顯示照片</span>
        <span v-else-if="data.value === 'rowPrice'">顯示直列價格</span>
        <span v-else-if="data.value === 'shippingSlip'">寄件單</span>
      </p>
      <div class="select-radio">
        <label>
          <input
            type="radio"
            :name="data.value"
            :value="true"
            v-model="data.selected"
            @click="updateConfiguration('shipperSetting', data)"
          />
          <span class="radio-style">顯示</span>
        </label>
        <label>
          <input
            type="radio"
            :name="data.value"
            :value="false"
            v-model="data.selected"
            @click="updateConfiguration('shipperSetting', data)"
          />
          <span class="radio-style">不顯示</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      storeId: 0,
      serverToken: '',
      setting: [],
    }
  },
  created() {
    this.initialization()
    this.getPrefer()
  },
  computed: {
    ...mapState('Prefer', {
      p_loading: state => state.p_loading,
      p_shipper: state => state.p_shipper,
    })
  },
  methods: {
    initialization() {
      this.storeId = this.$route.params.storeId
      this.serverToken = this.$methods.getCookie('serverToken')
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 更新偏好
    updateConfiguration(item, data) {
      this.$store.dispatch('Prefer/updateConfiguration', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken'),
        category: 'p_shipper',
        item: item,
        data: data
      })
    },
  },
}
</script>